.how-container-wrapper {
  position: relative;
  background-color: $bg-1;
  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 50vh;
    max-height: 56%;
    top: -1px;
    left: 0;
    background-color: $blue-1;
  }
  h2 {
    position: relative;
    font-size: calc(18px + 2vw);
    font-weight: 300;
    color: #ffffff;
    line-height: 1.2em;
    margin-right: calc(40px + 10vw);
    span {
      font-weight: 600;
      color: #0081c6;
    }
  }
  .how-steps {
    &-wrapper {
      margin-top: calc(100px + 10vw);
    }
    &-list {
      position: relative;
      min-width: 340px;
      max-width: 440px;
      transform: translateY(-50%);
      &-inner {
        padding-top: calc(21px + 0.8vw);
        padding-bottom: calc(21px + 0.8vw);
        background-color: #ffffff;
        // box-shadow: 0px 4px 20px rgb(216 123 123 / 20%);
        border-radius: 3px;
        h3 {
          font-size: $font-30;
          color: #000000;
          line-height: 1.25em;
          margin-bottom: calc(18px + 0.3vw);
          padding-left: calc(20px + 0.5vw);
          padding-right: calc(20px + 0.5vw);
          span {
            color: $blue-3;
            font-weight: 600;
          }
        }
        .nav-item {
          position: relative;
          &::before {
            content: "";
            display: block;
            width: 100%;
            height: 1px;
            position: absolute;
            top: -1px;
            left: 0;
            background-color: #f9fafc;
          }
          .nav-link {
            position: relative;
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: calc(20px + 0.8vw);
            padding-right: calc(20px + 0.8vw);
            user-select: none;
            outline: none;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
            cursor: pointer;
            .mobile-content{
              height: 0;
              overflow: hidden;
            }
            .label {
              &::before {
                content: "";
                z-index: 10;
                position: relative;
                display: inline-block;
                width: 12px;
                height: 12px;
                border-radius: 12px;
                background-color: $blue-1;
                left: -11%;
                top: 0;
                bottom: 0;
                -webkit-transform: translateX(0) scale(0);
                transform: translateX(0) scale(0);
                -webkit-transform-origin: center;
                transform-origin: center;
                -webkit-transition: -webkit-transform 0.5s
                  cubic-bezier(0.19, 1, 0.22, 1);
                transition: -webkit-transform 0.5s
                  cubic-bezier(0.19, 1, 0.22, 1);
                -o-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
              }
              .background {
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                background-color: #ffffff;
                z-index: 0;
                -webkit-box-shadow: 0px 10px 20px rgb(255 112 112 / 0%);
                box-shadow: 0px 10px 20px rgb(255 112 112 / 0%);
                border-radius: 0px;
                -webkit-transition: border-radius 0.5s
                    cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                transition: border-radius 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                -o-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  border-radius 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  border-radius 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  border-radius 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-box-shadow 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                -webkit-transform-origin: center;
                transform-origin: center;
              }
              .text {
                font-family: "Space Mono", monospace;
                font-size: 14px;
                color: $blue-1;
                line-height: 1em;
                opacity: 0.4;
                -webkit-transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                transition: opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                -o-transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1);
                transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  opacity 0.5s cubic-bezier(0.19, 1, 0.22, 1),
                  -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
              }
              p{
                display: none;
              }
            }
            &.active{
              // .label{
              //   margin-bottom: 5%;
              // }
              .mobile-content{
                opacity: .99;
                height: fit-content;
                padding-left: 12px;
                h4{
                  color: $black-1;
                  margin-bottom: 5%;
                }
                p{
                  color: $gray-1;
                  font-weight: 400;
                }
              }
            }
            &.active,
            &:hover {
              z-index: 10;
              .label {
                &::before {
                  -webkit-transform: translateX(15px) scale(1);
                  transform: translateX(15px) scale(1);
                }
                .background {
                  -webkit-transform: scale(1.06);
                  transform: scale(1.06);
                  -webkit-box-shadow: 0px 6px 10px -1px rgb(0 0 0 / 10%);
                  box-shadow: 0px 6px 10px -1px rgb(0 0 0 / 10%);
                  border-radius: 3px;
                }
                .text {
                  -webkit-transform: translateX(30px);
                  transform: translateX(30px);
                  opacity: 0.99;
                }
              }
            }
          }
        }
      }
    }
    &-description {
      position: relative;
      margin-top: calc(22px + 1vw);
      -webkit-perspective: 1000;
      perspective: 1000;
      -webkit-perspective-origin: top;
      perspective-origin: top;
      .counter {
        position: relative;
        display: block;
        width: 100%;
        padding-right: 2px;
        text-align: right;
        overflow: hidden;
        font-size: $font-26;
        font-weight: 400;
        color: $blue-1;
        line-height: 1.2em;
        letter-spacing: -0.02em;
        -ms-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1,
          "liga" 1, "dlig" 1, "swsh" 1;
        -o-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1,
          "liga" 1, "dlig" 1, "swsh" 1;
        -webkit-font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1,
          "liga" 1, "dlig" 1, "swsh" 1;
        font-feature-settings: "smcp" 1, "onum" 1, "frac" 1, "kern" 1, "liga" 1,
          "dlig" 1, "swsh" 1;
        -webkit-transform: translateY(-90%);
        transform: translateY(-90%);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        height: 40px;
        padding-top: 14px;
        user-select: none;
        span{
          position: relative;
          display: inline-block;
          -webkit-animation: slide-out-tr-position .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          animation: slide-out-tr-position .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
        }
        &.active{
          span{
            -webkit-animation: slide-out-tr .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: slide-out-tr .5s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          }
        }
      }
      &-content {
        overflow: hidden;
        .tab-pane{
          height: 0;
          display: block !important;
          h4{
            font-size: 0;
            animation-fill-mode: forwards;
            // -webkit-animation: slide-out-right .9s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            // animation: slide-out-right .9s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          }
          p{
            font-size: 0;
            animation-fill-mode: forwards;
            // -webkit-animation: slide-out-right 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            // animation: slide-out-right 1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          }
          &.active{
            height: 100%;
            h4{
              font-size: $font-32;
              -webkit-animation: slide-out-right-position .6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
                animation: slide-out-right-position .6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              // -webkit-animation: slide-out-right .6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              // animation: slide-out-right .6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            }
            p{
              font-size: $font-22;
              -webkit-animation: slide-out-right-position  .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              animation: slide-out-right-position  .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              // -webkit-animation: slide-out-right  .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              // animation: slide-out-right  .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            }
          }
          &.animationToglee{
            height: 100%;
            h4{
              font-size: $font-32;
              -webkit-animation: slide-out-right .6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: slide-out-right .6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              // -webkit-animation: slide-out-right-position .6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              // animation: slide-out-right-position .6s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            }
            p{
              font-size: $font-22;
              // -webkit-animation: slide-out-right-position  .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              // animation: slide-out-right-position  .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
              -webkit-animation: slide-out-right  .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            animation: slide-out-right  .7s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
            }
          }
          // &.animationToglee{
          //   height: 100%;
          //   h4{
          //     -webkit-animation: slide-out-right-position .9s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          //     animation: slide-out-right-position .9s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          //   }
          //   p{
          //     -webkit-animation: slide-out-right-position  1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          //     animation: slide-out-right-position  1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          //   }
          // }
          // &.animationToglee{
          //   h4{
          //     -webkit-animation: slide-out-right .9s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          //     animation: slide-out-right .9s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          //   }
          //   p{
          //     -webkit-animation: slide-out-right  1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          //     animation: slide-out-right  1s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
          //   }
          // }
        }
      }
      h4 {
        font-size: $font-32;
        color: #000000;
        line-height: 1.2em;
        margin-bottom: calc(15px + 0.5vw);
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
      p {
        font-size: $font-22;
        font-weight: normal;
        color: #676262;
        line-height: 1.2em;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
      }
    }
  }
}

@media only screen and (min-width: 1600px) {
  .how-container-wrapper:before {
    height: 65vh;
  }
}
@media only screen and (max-width: $min-query){
  .how-container-wrapper{
    .how-steps-wrapper{
      margin-top: calc(200px + 10vw);
    }
    .how-steps-list-inner{
      h3 {
        font-size: $font-mb-16;
      }
      .nav-item{
        display: flex;
        width: 100%;
        flex-flow: column;
        .nav-link{
          padding-top: 10px;
          padding-bottom: 10px;
          height: fit-content;
          .label{
            &::before{
              background-color: $blue-3;
              width: 6px;
              height: 6px;
            }
            .text{
              color: $blue-3;
              letter-spacing: -0.5px;
              font-size: $font-mb-12;
            }
          }
          .mobile-content{
            height: 0;
            animation: removeDeploymentBottom .1s ease-out;
            animation-fill-mode: forwards;
          }
          &.active{
            // height: auto;
            .mobile-content{
              padding-top: 5%;
              overflow: hidden;
              animation: deploymentBottom .4s ease-out;
              animation-fill-mode: forwards;
              h4{
                animation: deploymentBottomH4 .5s ease-out;
                animation-fill-mode: forwards;
                font-size: $font-mb-16;
              }
              p{
                animation: deploymentBottomP .5s ease-out;
                animation-fill-mode: forwards;
                font-size: $font-mb-12;
              }
            }
          }
        }
      }
    }
    h2{
      padding-left: 2%;
      font-size: $font-mb-22;
      line-height: 1.3;
      margin-right: 0;
    }
    .how-steps-list{
      transform: translateY(-34%);
      min-width: initial;
      margin-left: -4px;
      margin-right: -4px;
    }
  }
}