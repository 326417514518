#wrapper.home {
  height: 100vh;
  overflow: hidden;
}
.home-slider-wrapper {
  display: block;
  height: 100vh;
  position: relative;
  z-index: 2;
  background-color: $blue-1;
  overflow: hidden;
  .video-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 55vw;
    height: 100%;
    -webkit-perspective: 400px;
    perspective: 400px;
    z-index: 99999999;
    video {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      visibility: hidden;
      &.loop {
        // width: 100vw;
        // z-index: 2;
        // display: none;
        -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
        &.loop-active{
          z-index: 3 !important;
        }
      }
    }
    .element-cover {
      // position: relative;
      display: block;
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: 50% 50%;
      object-position: 50% 50%;
      overflow: hidden;
      // transition: 0.1s;
      // transition: all 600ms cubic-bezier(0.645, 0.045, 0.355, 1);
      &.intro {
        z-index: 3;
      }
    }
    .slider-bullets {
      position: absolute;
      top: calc(50% - 105px);
      left: 10px;
      z-index: 5;
      margin-bottom: 0;
      list-style: none;
      padding-left: 0;
      li {
        position: relative;
        margin-bottom: 15px;
        width: 2px;
        height: 60px;
        padding: 0 20px;
        // cursor: pointer;
        // opacity: 0.3;
        .bullet {
          display: block;
          position: relative;
          width: 2px;
          height: 60px;
          .empty {
            display: block;
            width: 2px;
            height: 60px;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.3;
          }
          .full {
            width: 2px;
            height: 0;
            position: absolute;
          }
        }
        &.active {
          .bullet .full {
            animation: scale_bullet $timing linear forwards;
          }
        }
      }
    }
    .button-scroll{
      position: absolute;
      bottom: 40px;
      left: $p-container;
      z-index: 10;
      color: #ffffff;
      font-size: $font-24;
    }
  }
  .right-col-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-top: calc(60px + 8vw);
    z-index: 1;
    .social {
      position: absolute;
      bottom: 40px;
      right: 0;
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
    }
  }
  .main-slider {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    padding-left: 0;
    transition: .3s all linear;
    li {
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 100%;
      color: #fff;
      opacity: 0;
      padding-right: 50px;
      &.animationTextHome{
        z-index: 9999;
        opacity: 1;
      }
    }
    .number {
      font-size: calc(20px + 2.6vw + 1.5vh);
      font-weight: 600;
      display: block;
      line-height: 0.8;
      opacity: 0;
    }
    h1 {
      font-size: calc(10px + 2.2vw + 1vh);
      // font-size: calc(25px + 2.5vw);
      font-weight: 300;
      margin-top: 30px;
      position: relative;
      // opacity: 0;
      span{
        display: block;
        opacity: 0;
        // visibility: hidden;
        // left: -600px;
        position: absolute;
        // height: 0;
        animation: line_enter 1s linear;
        transition: 1s all linear;
        // animation-delay: 2s all;
        &.active{
          // height: initial;
          // opacity: 1 !important;
          animation: line_enter_back 1s linear;
        }
      }
      strong {
        font-weight: 400;
      }
    }
  }
  // .carousel.slide {
  //   height: 100vh;
  //   .carousel-inner {
  //     height: 100%;
  //   }
  //   .carousel-item {
  //     height: 100%;
  //     img {
  //       position: absolute;
  //       top: 0;
  //       left: 0;
  //       // opacity: 0;
  //       // visibility: hidden;
  //       width: 100%;
  //       height: 100%;
  //       object-fit: cover;
  //       object-position: 50% 50%;
  //     }
  //   }
  // }
  .container {
    height: 100%;
    .row {
      height: 100%;
    }
  }
  .email-wrapper {
    padding-bottom: 40px;
    a {
      color: #fff;
      text-decoration: none;
    }
  }
  .page-header-background {
    display: block;
    position: absolute;
    top: 0;
    left: 55vw;
    width: 45vw;
    height: 100%;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
    background-color: $blue-1;
    z-index: -1;
    // transition: .4s all ease;
    .page-header-background-inner {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: $blue-1;
      -webkit-transform-origin: top;
      transform-origin: top;
      // transition: 0.1s;
      &::before {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 100%;
        height: 50px;
        opacity: 0.4;
        -webkit-box-shadow: 0 2.8px 3.5px -5px rgb(0 0 0 / 7%), 0 6.7px 8.4px -5px rgb(0 0 0 / 11%), 0 12.5px 15.8px -5px rgb(0 0 0 / 13%), 0 22.3px 28.1px -5px rgb(0 0 0 / 16%), 0 41.8px 52.6px -5px rgb(0 0 0 / 19%), 0 100px 126px -5px rgb(0 0 0 / 26%);
        box-shadow: 0 2.8px 3.5px -5px rgb(0 0 0 / 7%), 0 6.7px 8.4px -5px rgb(0 0 0 / 11%), 0 12.5px 15.8px -5px rgb(0 0 0 / 13%), 0 22.3px 28.1px -5px rgb(0 0 0 / 16%), 0 41.8px 52.6px -5px rgb(0 0 0 / 19%), 0 100px 126px -5px rgb(0 0 0 / 26%);
      }
    }
  }
}

@media only screen and (max-width: $min-query) {
  .home-slider-wrapper {
    // height: 55vh;
    .img-rocket {
      display: initial !important;
    }
    .main-slider {
      li{
        padding-right: 10vw;
        .number {
          font-size: $font-mb-36;
        }
        h1 {
          margin-top: 30px;
          font-size: $font-mb-22;
          span{
            white-space: pre-wrap;
          }
        }
      }
    }
    .right-col-wrapper {
      position: initial;
      padding-top: 0;
      margin-top: calc(20px + 8vh);
      padding-left: 0;
      .email-wrapper {
        position: absolute;
        bottom: 0;
        top: 46vh;
        a {
          font-size: $font-mb-12;
        }
      }
      .social {
        flex-direction: initial;
        padding-right: calc(15.71429px + 1vw) !important;
        top: 44.5vh;
        li {
          margin: 4px;
          a {
            width: calc(26px + 1.5vw);
            height: calc(26px + 1.5vw);
            svg {
              max-height: 14px;
            }
          }
        }
      }
    }
    .video-wrapper {
      bottom: 0;
      // width: 100vw;
      margin-top: 55vh;
      height: 45vh;
      width: 100vw;
      .background {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        background-color: #0b4f9c;
        -webkit-transform: scaleY(0);
        transform: scaleY(0);
        -webkit-transform-origin: bottom;
        transform-origin: bottom;
      }
      .slider-bullets {
        top: -40vh;
        right: $p-container;
        left: auto;
        li{
          height: 30px;
          padding: 0 5px;
          .bullet{
            height: 30px;
            // background: #1386c7;
            width: 1px;
            .empty{
              height: 30px;
              width: 1px;
            }
            .full{
              width: 1px;
              // background: #37d3ff;
              // background-color: none !important;
            }
          }
        }
      }
    }
    .page-header-background {
      width: 100vw;
      left: 0;
    }
  }
}
