.main-menu-wrapper {
  padding-top: 40px;
  display: block;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  z-index: 10;
  transition: .4s all ease;
  &.about{
    // background: $blue-1;
    .menu-items li span{
      &.active{
        color: $blue-12;
      } 
    }
    .logo-wrapper{
      svg{
        fill: $blue-11;
      }
    }
  }
  &.services{
    // background: $blue-2;
    .menu-items li span{
      &.active{
        color: $blue-12;
      }
    }
    .logo-wrapper{
      svg{
        fill: white;
      }
    }
  }
  &.lest-talk{
    // background: $blue-3;
    .menu-items li span{
      &.active{
        color: $blue-12;
      }
    }
    .logo-wrapper{
      svg{
        fill: white;
      }
    }
  }
  .container {
    display: flex;
    align-items: center;
    .row {
      width: 100%;
      // align-items: flex-end;
      align-items: end;
      flex: 1;
    }
  }
  .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-backdrop-filter: blur(30px);
    backdrop-filter: blur(30px);
    opacity: 0;
    -webkit-transition: opacity 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
    -o-transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
    transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out,
      -webkit-transform 0.25s ease-in-out;
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top;
    transform-origin: top;
    -webkit-box-shadow: 0px 10px 65px 0px rgb(0 0 0 / 5%);
    box-shadow: 0px 10px 65px 0px rgb(0 0 0 / 5%);
    z-index: -10;
  }
  .content-logo-wrapper{
    .ellipsis{
      display: none;
    }
    .icon-close{
      display: none;
    }
  }
  .logo-wrapper {
    width: fit-content;
    svg {
      cursor: pointer;
      max-width: 168px;
      fill: $blue-8;
      width: calc(2.5vw + 100px);
      transition: 1s all ease;
    }
  }
  .dropdown {
    margin-left: auto;
    display: table;
    margin-bottom: 10px;
    .dropdown-toggle {
      padding: 0;
      box-shadow: 0 0 !important;
      img{
        width: 20px;
        margin-right: 6px;
      }
      &::after {
        color: #fff;
        border-top: 0.4em solid;
        border-right: 0.3em solid transparent;
        border-left: 0.3em solid transparent;
      }
    }
  }
  .menu-items {
    display: flex;
    justify-content: space-between;
    position: relative;
    text-align: right;
    width: 100%;
    padding-left: 0;
    margin-bottom: 0;
    overflow: hidden;
    li {
      position: relative;
      display: inline-flex;
      opacity: 0;
      span {
        font-size: $font-24;
        font-weight: 400;
        color: #fff;
        text-decoration: none;
        transition: .4s all ease;
        cursor: pointer;
        z-index: 999999;
        // &.active{
        //   color: $blue-8;
        // }
      }
    }
  }
  // &.noExpanded{
  //   transition-delay: 0s !important;
  // }
  &.activeNavbar{
    // transition: .3s all;
    background: #fff !important;
    padding: 16px 0;
    height: 68px;
    width: 100vw;
    // transition: .8s all ease;
    // padding-top: 0;
    >.container{
      height: 100%;
      .row{
        align-items: center;
        flex-wrap: nowrap;
      }
    }
    .logo-wrapper svg{
      fill: $blue-1;
    }
    .dropdown{
      display: none;
    }
    .menu-items{
      span{
        color: $gray-1;
        &:hover{
          color: $blue-7;
        }
        &.active {
          color: $blue-1;
        }
      }
    }
    .content-logo-wrapper .ellipsis{
      color: $blue-1;
      fill: $blue-1;
    }
    &.expanded {
      // transition-delay: .7s !important;
      transition-delay: 0s !important;
      &.about{
        background: $blue-1 !important;
      }
      &.services{
        background: $blue-2 !important;
      }
      &.lest-talk{
        background: $blue-3 !important;
      }
    }
    &.noExpanded{
      &.about{
        .menu-items li span.active{
          color: $blue-7;
        }
        .logo-wrapper{
          svg{
            fill: $blue-10;
          }
        }
      }
      &.services{
        .menu-items li span.active{
          color: $blue-7;
        }
        .logo-wrapper{
          svg{
            fill: $blue-2; 
          }
        }
      }
      &.lest-talk{
        .menu-items li span.active{
          color: $blue-7;
        }
        .logo-wrapper{
          svg{
            fill: $blue-3; 
          }
        }
      }
    }
  }
}
.responsive .responsiveDropdown{
  .collapseHeader{
    display: none;
  }
  a{
    font-size: 0;
  }
}
@media only screen and (max-width: $min-query){
  .responsive .responsiveDropdown{
    a{
      font-size: 12px;
    }
  }
  .main-menu-wrapper{
    padding: 16px 0;
    height: 68px;
    align-items: center;
    flex-wrap: nowrap;
    display: flex;
    .logo-wrapper svg{
      width: calc(8vw + 50px);
      max-width: 94px;
      margin-left: -6px
    }
    .content-logo-wrapper{
      display: flex;
      justify-content: space-between;
      align-items: center;
      z-index: 300;
      padding: 0;
      width: 100%;
      .ellipsis, .icon-close{
        display: initial;
        width: 6vw;
        max-width: 50px;
        cursor: pointer;
        color: white;
        transition: 1s all ease;
      }
    }
    .container{
      flex: 1;
      padding: 0 $p-mb-container;
      .row{
        align-items: center;
      }
    }
    .dropdown{
      display: none;
    }
    &.noExpanded{
      transition-delay: 0s !important;
    }
    &.activeNavbar{
      &.noExpanded{
        // transition-delay: .4s !important;
      }
    }
    .responsive{
      &.active{
        .optionsDropdownMobile{
          display: flex;
          width: 100vw;
          height: 92vh;
          // background: $blue-1;
          z-index: 200;
          position: absolute;
          left: 0;
          top: 8vh;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding:  $p-mb-container;
          opacity: .9;
          background: $blue-1;
          transition: .4s all linear;
          &.about{
            background: $blue-1 !important;
          }
          &.services{
            background: $blue-2 !important;
          }
          &.lest-talk{
            background: $blue-3 !important;
          }
          &.close-active{
            -webkit-animation: scale-out-ver-top .7s cubic-bezier(0.895, 0.030, 0.685, 0.220) 0.2s forwards;
            animation: scale-out-ver-top .7s cubic-bezier(0.895, 0.030, 0.685, 0.220) 0.2s forwards;
          }
          &.active{
            -webkit-animation: scale-in-ver-top 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) backwards;
            animation: scale-in-ver-top 1s cubic-bezier(0.075, 0.820, 0.165, 1.000) backwards;
          }
          .ellipsis{
            width: 50px;
            height: fit-content;
          }
          button{
            width: 8vw;
            max-width: 50px;
            img{
              object-fit: contain;
              width: 32px;
              max-width: 60px;
              margin-right: 10px;
            }
            &::after{
              display: none;
            }
          }
          .collapseHeader{
            display: flex;
            width: 100%;
            padding-right: $p-mb-container;
            margin-right: 8%;
            .dropdown{
              display: initial;
              button{
                &::after{
                  display: initial;
                  position: absolute;
                  top: 10px;
                  border-top: 0.58em solid;
                  border-right: 0.45em solid transparent;
                  border-left: 0.45em solid transparent;
                }
              }
            }
          }
          ul{
            flex: 1;
            text-align: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding-left: 0;
            margin-bottom: 0;
            li{
              span{
                font-size: $font-mb-36;
                color: white;
                line-height: 1.6;
                cursor: pointer;
                text-decoration: none;
              }
            }
          }
          .footerDropdownLenguague{
            display: flex;
            justify-content: space-between;
            width: 100%;
            a{
              cursor: pointer;
              font-size: $font-mb-12;
              color: white;
              &:last-child{
                font-weight: bold;
                margin-left: 10px;
              }
            }
          }
        }
      }
      .optionsDropdownMobile{
        height: 0;
        overflow: hidden;
      }
    }
    &.about{
      .responsive.active .optionsDropdownMobile ul li span.active{
        color: $blue-9; 
      }
    }
    &.services{
      .responsive.active .optionsDropdownMobile ul li span.active{
        color: $blue-9; 
      }
    }
    &.lest-talk{
      .responsive.active .optionsDropdownMobile ul li span.active{
        color: $blue-13; 
      }
    }
    .menu-items{
      display: none;
    }
    &.expanded{
      // background: $blue-1;
      .content-logo-wrapper{
        .logo-wrapper{
          svg{
            fill: white;
          }
        }
      }
    }
  }
}