.about {
  .page-header,
  .views-container {
    background-color: $blue-1;
  }
  .page-footer,
  .page-footer-wrapper {
    background-color: $blue-2;
  }
}
.what-container {
  padding-bottom: calc(40px + 3vw);
  background-color: $blue-1;
  .what-title-wrapper {
    position: relative;
    padding-left: 8%;
    p {
      position: relative;
      font-size: $font-32;
      color: #ffffff;
      line-height: 1.2em;
      max-width: 75%;
    }
  }
  .what-fields-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: calc(37px + 3vw);
    padding-right: 16%;
    span {
      position: relative;
      width: 5vw;
      min-width: 5vw;
      margin-right: 1.25em;
      top: -1px;
      font-size: $font-20;
      color: #2c7dfa;
      text-transform: uppercase;
      white-space: nowrap;
      font-weight: 500;
      text-align: right;
      animation: line_enter 2s ease-out;
      animation-fill-mode: forwards;
      top: 3px;
    }
    p {
      font-size: $font-22;
      color: #ffffff;
      line-height: 1.6em;
    }
  }
}
@media only screen and (max-width: $min-query){
  .page-header{
    .page-header-wrapper{
      .text-wrapper {
        height: auto;
        min-height: 50vh;
        justify-content: space-between;
        flex-direction: column-reverse;
        margin-bottom: 0;
        .right-col{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-left: 0;
          h2{
            // padding-left: 2%;
            font-size: $font-mb-20;
            line-height: 1.3;
            font-weight: 300;
            span{
              font-weight: 300;
            }
          }
        }
        .left-col{
          padding-left: 0;
          h1{
            padding-left: 6%;
            margin: 15px 0;
            // top: -42px;
            // left: -18px;
            position: relative;
            span{
              font-size: $font-mb-20;
              font-weight: 200;
            }
          }
        }
        h3{
          margin-bottom: 10%;
        }
      }
    }
  }
  .what-container{
    .what-container-wrapper{
      margin: 0;
      .what-title-wrapper{
        padding-left: 0;
        padding-bottom: calc(40px + 3.5vw);
        p{
          padding-left: 2%;
          max-width: initial;
          font-size: $font-mb-16;
          line-height: 1.4em;
        }
      }
    }
    .what-fields-wrapper{
      padding-right: 0;
      span{
        width: initial;
        min-width: 40px;
        margin-right: 2.25em;
        font-size: $font-mb-10;
      }
      p{margin-bottom: 0;font-size: $font-mb-10;}
    }
  }
}

@import "./how";
@import "./partners";
@import "./carousel";
@import "./fields";
@import "./clients";
