html, body {
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  overflow-scrolling: touch;
  -webkit-text-rendering: optimizeLegibility;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  background-color: #ffffff;
}
* {
  font-family: "Montserrat";
  font-weight: 300;
}
.container {
  padding-left: $p-container !important;
  padding-right: $p-container !important;
  max-width: initial !important;
}

ol,
ul,
dl {
  list-style: none;
  padding-left: 0;
  margin: 0;
}

body,
#wrapper {
  position: relative;
  min-height: 100vh;
}

#loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: $blue-1;
  top: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  svg {
    max-height: 10vh;
    fill: $blue-8;
  }
  img {
    filter: opacity(0.5) drop-shadow(0 0 0 $blue-8);
    max-height: 50px;
    margin-top: 30px;
    animation: spin 2s infinite linear;
    -webkit-animation: spin 2s infinite linear;
  }
}
@keyframes spin {
	from { -webkit-transform: rotate(0deg); }
	to { -webkit-transform: rotate(359deg); }
}

.social {
  padding-left: 0;
  display: flex;
  z-index: 10000;
  li {
    margin: 5px;
    margin: 5px;
    display: inline-block;
    a {
      background: #fff;
      width: calc(26px + 1vw);
      height: calc(26px + 1vw);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        fill: $blue-1;
      }
    }
  }
}
.about{
  .page-footer-wrapper{
    background: $blue-2;
  }
}
// &.about{
//   background: $blue-1;
// }
.page-header {
  position: relative;
  z-index: 1;
  padding-top: calc(45px + 4vw);
  padding-bottom: calc(40px + 3vw);
  transition: .2s all linear;
  .page-header-wrapper {
    .text-wrapper {
      position: relative;
      // height: 70vh;
      min-height: 70vh;
      // opacity: 0;
      margin-bottom: calc(25px + 1.5vw);
      .left-col {
        align-self: flex-end;
        position: relative;
        h1 {
          font-size: $font-85;
          color: #fff;
          font-weight: 200;
          padding-left: 2vw;
          padding-bottom: 10px;
          margin-bottom: 0;
          span{
            display: inline-block;
            position: relative;
            font-size: $font-74;
            opacity: 0;
            // animation-delay: 0.15s !important;
            // animation: line_enter 2s ease-out;
            // animation-fill-mode: forwards;
          }
        }
      }
      .right-col {
        position: relative;
        margin-top: calc(55px + 4.5vw);
        h2 {
          font-size: $font-48;
          font-weight: 400;
          color: #fff;
          span {
            position: relative;
            // animation-delay: 0.15s !important;
            // animation: line_enter 2s ease-out;
            // animation-fill-mode: forwards;
            display: block;
            font-weight: 400;
            overflow: hidden;
            opacity: 0;
          }
        }
      }
      h3{
        position: relative;
        // overflow-x: hidden;
        margin-bottom: 22%;
        line-height: 1;
        .text-word {
          color: white;
          font-size: $font-24;
          display: block;
          opacity: 0;
          overflow: hidden;
          line-height: 1.2;
        }
      }
    }
    .image-wrapper {
      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}
.page-content {
  position: relative;
  z-index: 1;
  transition: .2s all linear;
}
.row.expanded{
  max-width: none;
}
.title-section {
  position: relative;
  line-height: 1em;
  font-size: $font-74;
  .text-line {
    display: block;
    font-weight: 400;
    opacity: 0;
  }
}
.ml-custom {
  margin-left: 14%;
}
.views-container {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 8;
  overflow: auto;
  div{
    &:first-child{
      // transition: .2s all linear;
      // &::-webkit-scrollbar-thumb{
      //   width: 0;
      //   background: red;
      // }
    }
  }
}
.textLink{
  display: flex;
  justify-content: center;
  overflow: hidden;
  text-decoration: none;
  color: $blue-8 !important;
  cursor: pointer;
  animation: heartbeat 1.5s ease-in-out infinite both;
  div{
    display: flex;
    flex-direction: column;
    position: relative;
    strong{
      transition: .3s all linear;
      &:last-child{
        position: absolute;
        top: 100px;
      }
    }
  }
  cursor: pointer;
}
.no_mb{
  display: none;
}
.no_desktop{
  display: block;
}
.btn-outline-white{
  border: 1px solid white !important;
  border-radius: 30px;
  color: white;
  padding: 8px 35px;
  font-size: $font-24;
  // background-color: $blue-2 !important; //validar 
  white-space: nowrap;
  transition: .4s;
  box-shadow: none;
  &:hover{
    background-color: $blue-5 !important;
    border-color: $blue-5 !important;
    color: white;
  }
}

.content-arrow-blue{
  position: absolute;
  overflow: hidden;
  // width: 20%;
  bottom: -12px;
  max-width: 100px;
  // left: 0px;
  svg{
    fill: none;
    stroke: $blue-8;
    width: 100%;
    stroke-dasharray: 450;
    stroke-dashoffset: 450;
    opacity: 0;
  }
}
.divAnimationChangePage{
  bottom: 0;
  position: fixed;
  width: 100vw;
  height: 0;
  // background: red;
  z-index: 9;
  transition: .02s all linear;
}
@media only screen and (max-width: $min-query){
  #loading {
    svg {
      max-height: 60px;
    }
    img {
      max-height: 40px;
    }
  }
  .page-content {
    margin-bottom: 60vh;
    // transform: translateY(50vh);
  }
  .content-arrow-blue{
    width: 12%;
    bottom: -10px;
    left: 3%;
  }
  .no_mb{
    display: block;
  }
  .no_desktop{
    display: none;
  }
  .btn.btn-outline-secondary{
    border: 2px solid white;
    color: white;
    border-radius: 32px;
    font-size: $font-mb-14;
    font-weight: 400;
    padding: 14px 40px;
    background: none;
    box-shadow: none;
    &:hover{
      border: 2px solid white;
      color: white;
      background: none;
    }
  }
  .ml-custom{
    margin-left: 0;
  }
  .row{
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .container{
    padding-left: $p-mb-container !important;
    padding-right: $p-mb-container !important;
  }
  .image-wrapper{
    img{
      object-fit: contain;
      width: 100%;
    }
  }
}