.clients-container {
  background-color: $blue-1;
  padding-top: calc(40px + 5vw);
  padding-bottom: calc(40px + 5vw);
  overflow: hidden;
  h2 {
    color: #fff;
    font-size: $font-62;
    line-height: 1.2em;
    margin-bottom: calc(50px + 2vw);
  }
  p {
    color: #fff;
    font-size: $font-25;
    max-width: 870px;
    margin-bottom: calc(70px + 3vw);
    span{
      display: block;
      opacity: 0;
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    li {
      flex: 0 0 20%;
      opacity: 0;
      padding: 20px;
    }
  }
}
.swiper{
  .swiper-slide{
    cursor: pointer;
  }
  &.clients{
    .swiper-wrapper{
      padding: 20px;
      .swiper-slide {
        height: 100px;
        display: flex;
        align-items: center;
        img {
          max-width: 60%;
          max-height: 100%;
          margin: auto;
          display: block;
          object-fit: contain;
        }
      }
    }
  }
}
@media only screen and (max-width: $min-query){
  .swiper.clients{
    .swiper-wrapper{
      padding: 20px 0;
      .swiper-slide img{
        max-width: 80%;
      }
    }
    .swiper-slide {
      cursor: pointer;
      display: block;
      // width: auto !important;
      position: relative;
      height: 100%;
      overflow: hidden;
      margin: auto;
      li{
        text-align: center;
        img{
          // width: 100%;
          width: 80%;
          object-fit: cover;
        }
      }
      // &:last-child{
      //   margin-right: 0;
      // }
    }
  }
  .clients-container{
    margin-bottom: 0;
    padding-bottom: 30px;
    p{
      margin-bottom: 0;
    }
    h2{
      margin-bottom: calc(20px + 2vw);
      font-size: calc(20px + 2vw);
      span{
        display: block;
      }
    }
    ul{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      flex: 1 1;
      padding: 0 5%;
      li{
        padding: 10px;
        width: 50%;
        flex: 0 0 50%;
        margin-top: 10%;
        img{
          width: 100%;
          max-width: 200px;
          object-fit: contain;
        }
      }
    }
  }
}