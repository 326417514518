.page-footer {
    position: fixed;
    width: 100%;
    height: 100vh;
    bottom: 0;
    z-index: 0;
  .page-footer-wrapper {
    position: relative;
    height: 100vh;
    .container {
      min-height: 60vh;
      margin-top: 40vh;
      padding-top: 5%;
      padding-bottom: 7%;
      display: flex;
      flex-direction: column;
      justify-content: end;
      transition: .2s all linear;
      .content-dinamic-info{
        margin-bottom: 100px;
      }
    }
    .title-container {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      height: 100%;
      .main-link {
        position: relative;
        color: #fff;
        text-decoration: none;
        &.page-title-link {
          padding-top: 18px;
          width: calc(150px + 6vw);
        }
      }
      .next-page-title {
        font-size: $font-32;
        font-weight: 600;
        span {
          font-weight: 300;
          margin-left: 15px;
          font-size: $font-30;
        }
      }
      .punch-line {
        font-size: $font-74;
        font-weight: 200;
      }
    }
  }
  .page-footer-links {
    // position: absolute;
    // bottom: 65%;
    // left: 0;
    // width: 100%;
    display: flex;
    align-items: center;
    padding: 0 $p-container;
    // margin-bottom: -4%;
    .social{
      width: 100vw;
      justify-content: center;
    }
    a {
      color: #fff;
      text-decoration: none;
      font-size: calc(15px + 0.08vw);
      white-space: nowrap;
      &.email-wrapper {
        font-weight: 600;
      }
    }
  }
}
.content-button-work{
  display: none;
}
@media only screen and (max-width: $min-query){
  .content-button-work{
    display: block;
    text-align: center;
    margin: auto;
    width: 100%;
    margin-top: 12px;
  }
  .page-footer{
    // position: initial;
    // height: 65vh;
    .page-footer-links{
      padding: 0 0 calc(22.85714px + 1.0084vw) 0;
      // flex-flow: wrap;
      justify-content: space-around;
      margin-bottom: 0;
      bottom: 0;
    }
    .page-footer-wrapper {
      height: 100%;
      .container{
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
        .content-dinamic-info{
          margin-bottom: 38%;
        }
      }
      .title-container {
        flex-direction: column;
        height: auto;
        // margin-top: 10vh;
        align-items: center;
        // margin-bottom: ;
        .page-title-link{
          display: none;
        }
        .main-link{
          text-align: center;
          line-height: 1;
          // padding-top: 4%;
          margin: 10% 0 8%;
        }
        .punch-line {
          font-weight: 300;
          font-size: calc(26px + 3.5vw);
        }
      }
    }
    .page-footer-links{
      bottom: 8%;
      // padding: 0 $p-mb-container $p-mb-container;
      .social{
        // margin-bottom: 30px;
        // margin-top: -80px;
        margin: -100px 0 30px 5%;
        li a{
          svg{
            fill: $blue-2;
            width: 15px;
            height: 15px;
          }
        }
      }
      a{
        font-size: $font-mb-12;
      }
      .option-blog{
        margin-right: calc($p-mb-container-negative * 2);
      }
      .email-wrapper{
        margin-left: calc($p-mb-container-negative * 2);
      }
    }
  }
}