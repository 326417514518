@keyframes deploymentBottom{
  0% {
    height: 0px;
  }
  20% {
    height: 4vh;
  }
  60% {
    height: 12vh;
  }
  100% {
    height: fit-content;
  }
}
@keyframes removeDeploymentBottom{
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes deploymentBottomH4{
  0%{
    margin-left: -30px;
    opacity: 0;
  }
  100%{
    margin-left: 0px;
    opacity: 1;
  }
}

@keyframes deploymentBottomP{
  0%{
    margin-left: -20px;
    opacity: 0;
  }
  100%{
    margin-left: 0px;
    opacity: 1;
  }
}
@keyframes removeOptionsNavbar{
  0%{
    opacity: 1;
    height: 90vh;
  }
  100%{
    opacity: 0;
    height: 0;
  }
}
@keyframes removeTextOptionsNavbarLi{
  0%{
    opacity: 1;
    font-size: $font-mb-14;
  }
  100%{
    opacity: 0;
    font-size: 0;
  }
}
//animation Steps --------------------------------------------------------
@keyframes slide-out-right {
  0% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateX(1000px);
            transform: translateX(1000px);
    opacity: 0;
  }
}

@keyframes slide-out-right-position {
  0% {
    -webkit-transform: translateX(-1000px);
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-out-tr {
  0% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
  100% {
    -webkit-transform: translateY(-50px) translateX(500px);
    transform: translateY(-50px) translateX(50px);
  }
}
@keyframes slide-out-tr-position {
  0% {
    -webkit-transform: translateY(50px) translateX(-50px) ;
    transform: translateY(50px) translateX(-50px);
  }
  100% {
    -webkit-transform: translateY(0) translateX(0);
    transform: translateY(0) translateX(0);
  }
}
//fin de animación de los Steps --------------------------------------------------------
@keyframes line_enter { 
  from {  transform: translateY(100%) rotate(4deg) translateZ(0); } 
  to {   transform:translateY(0%) translateZ(0); }
}
@keyframes line_enter_back { 
  from { transform: translateY(0) rotate(0) translateZ(0); } 
  to {   transform: translateY(-100%) rotate(-10deg) translateZ(0); }
}
@keyframes slide-out-top { // imagenes de abajo hacia arriba con leve movimiento de rebote
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }
  80%{
    -webkit-transform: translateY(-16px);
    transform: translateY(-16px);
  }
  90%{
    -webkit-transform: translateY(-8px);
    transform: translateY(-8px);
    opacity: .9;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
            transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
            transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
            transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
            transform-origin: top;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(200px);
            transform: translateY(200px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

@keyframes scale-out-ver-top {
  0% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100vw 0%;
            transform-origin: 100vw 0%;
    opacity: .9;
  }
  100% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100vw 0%;
            transform-origin: 100vw 0%;
    opacity: .9;
  }
}

@keyframes scale-in-ver-top {
  0% {
    -webkit-transform: scaleY(0);
            transform: scaleY(0);
    -webkit-transform-origin: 100vw 0%;
            transform-origin: 100vw 0%;
    opacity: -9;
  }
  100% {
    -webkit-transform: scaleY(1);
            transform: scaleY(1);
    -webkit-transform-origin: 100vw 0%;
            transform-origin: 100vw 0%;
    opacity: .9;
  }
}

@keyframes scale-created {
  0%{
    stroke-dashoffset: 450;
    opacity: 0;
    fill: none;
  }
  70%{
    fill: $blue-2;
  }
  100%{
    stroke-dashoffset: 0;
    opacity: 1;
    fill: $blue-8;
  }
}

@keyframes bg-pan-top {
  0% {
    background-position: 50% 100%;
    background: rgba(255, 255, 255,0.8) !important;
  }
  100% {
    background-position: 50% 0%;
  }
}

@keyframes bg-pan-bottom {
  0% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 50% 100%;
    background: rgba(255, 255, 255,0.8) !important;
  }
}

@keyframes slideRight {
  0% {
    opacity: 0;

  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes slide-top-home {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes scale_bullet{
  from{
    height: 0;
  }
  to{
    height: 100%;
  }
}