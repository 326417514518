.where-container {
  background-color: $bg-1;
  .images-slider-wrapper {
    position: relative;
    background-color: #fef5f5;
    width: 100%;
    // .slick {
    //   &-track {
    //     display: inline-flex;
    //     white-space: nowrap;
    //     height: 70vh;
    //   }
    //   &-list {
    //   }
    //   &-slide {
    //     display: block;
    //     width: auto !important;
    //     position: relative;
    //     height: 100%;
    //     margin: 0 10px;
    //     overflow: hidden;
    //     .item {
    //       height: 70vh;
    //       img {
    //         width: 100%;
    //         height: 100%;
    //       }
    //     }
    //   }
    // }
    .swiper {
      height: 70vh;
      &-wrapper {
        display: inline-flex;
        white-space: nowrap;
      }
      &-slide {
        display: block;
        width: auto;
        position: relative;
        height: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  // ul {
  //   position: relative;
  //   display: flex;
  //   height: 70vh;
  //   white-space: nowrap;
  //   scroll-snap-type: x mandatory;
  //   overflow-x: auto;
  //   -webkit-overflow-scrolling: touch;
  //   scroll-behavior: smooth;
  //   padding: 0;
  //   list-style: none;
  //   li {
  //     display: block;
  //     width: auto;
  //     // position: relative;
  //     height: 100%;
  //     margin: 0 10px;
  //     // overflow: hidden;
  //     scroll-snap-align: center;
  //     img {
  //       width: auto;
  //       max-width: none;
  //       height: 100%;
  //       user-select: none;
  //       pointer-events: none;
  //       user-drag: none;
  //       -webkit-user-drag: none;
  //       -moz-user-select: none;
  //     }
  //   }
  // }
}
