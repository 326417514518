.services {
  .views-container{
    .page-header{
      padding-bottom: 0;
      background: $blue-2;
    }
    .page-footer,
    .page-footer-wrapper{
      background: $blue-3;
    }
  }
  .page-footer .page-footer-links .social li a svg{
    fill: $blue-2 !important;
  }
  .views-container {
    background-color: $blue-2;
  }
}
.work-list-container{
  background-color: #fff;
  position: relative;
  z-index: 10;
  padding-bottom: calc(40px + 4vw);
  // top: 42vh;
  h1 {
    font-size: calc(10px + 2vw);
  }
  p {
    line-height: 1.8em;
    padding-bottom: calc(25px + 3vw);
    font-size: calc(15px + .08vw);
    color: $gray-2;
  }
  .items_container {
    margin-left: 0;
    margin-right: 0;
    padding: 0 calc($p-container - (var(--bs-gutter-x) * 0.5));
    justify-content: center;
    .columns{
      // margin-top: -20vh;
      z-index: 10;
    }
    .item {
      text-align: center;
      margin-bottom: calc(20px + 3vw);
      svg {
        display: block;
        margin: auto auto calc(5px + 1vw);
        height: calc(20px + 2vw);
        fill: $blue-10;
      }
      span {
        display: block;
        font-size: calc(15px + .08vw);
        font-weight: 500;
        color: $blue-1;
      }
    }
  }
  .tab-content {
    margin-top: calc(30px + 3vw);
    text-align: center;
  }
}
.block-bg-cover{
  overflow: hidden;
  cursor: pointer;
  position: relative;
  .element-cover {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: 50% 50%;
    object-position: 50% 50%;
    overflow: hidden;
  }
  &:hover{
    .picture{
      -webkit-animation: kenburns-top 5s ease-out both;
	        animation: kenburns-top 5s ease-out both;
    }
    .view-project{
      opacity: 1;
    }
  }
}
.work-thumb-wrapper {
  position: relative;
  margin-bottom: calc(40px + 3.5vw);
  .text-wrapper{
    cursor: pointer;
    a{
      text-decoration: none;
    }
    .work-type {
      display: block;
      position: relative;
      margin: 16px 0;
      margin-top: 16px;
      margin-bottom: 16px;
      font-size: 12px;
      font-weight: normal;
      line-height: 1.1em;
      letter-spacing: 0.07em;
      text-transform: uppercase;
      color: $blue-2;
      opacity: 0;
    }
    .title{
      position: relative;
      margin-bottom: 14px;
      font-weight: normal;
      font-size: $font-32;
      font-weight: normal;
      line-height: 1.1em;
      color: #000000;
      opacity: 0;
    }
    .work-tags{
      position: relative;
      padding-left: 0;
      opacity: 0;
      li{
        position: relative;
        display: inline-block;
        font-weight: normal;
        font-size: 14px;
        font-weight: normal;
        color: #747474;
        line-height: 1.1em;
      }
    }
  }
  .picture{
    img{
      width: 100%;
      height: 410px;
      object-fit: cover;
    }
  }
  .view-project{
    position: absolute;
    top: 0;
    left: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.3);
    -webkit-transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    -o-transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    transition: 1s cubic-bezier(0.19, 1, 0.22, 1);
    h3{
    font-size: calc(24.57143px + 1.2605vw);
    font-weight: 500;
    color: #ffffff;
    letter-spacing: -0.01em;
    }
  }
}
.work-buttons{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5% $p-container;
  background: $blue-2;
  .btn-outline-white{
    margin-right: 30px;
    &:last-child{
      margin-right: 0;
    }
  }
  .nav-item {
    margin: 10px;
  } 
  .nav-link {
    border: 1px solid #fff;
    border-radius: 30px;
    color: #fff;
    font-size: calc(9px + .8vw);
    padding: 8px 35px;
    transition: .4s;
    white-space: nowrap;
    cursor: pointer;
    &.active {
      background-color: $blue-5;
      border-color:  $blue-5;
    }
  }
}
@media only screen and (max-width: $min-query){
  .work .page-header .page-header-wrapper{
    .text-wrapper{
      .left-col{
        order: 1
      }
      .right-col{
        order: 2;
        h3{
          .text-word {
            font-size: $font-mb-10;
            display: block;
            // padding-left: 2%;
          }
        }
        h2{
          padding-left: 0;
          font-size: $font-mb-25;
        }
      }
    }
  }
  .work-list-container{
    .row {
      padding: 0 $p-mb-container;
    }
    .tab-content {
      margin-top: calc(40px + 8vw);
    }
    &::after {
      height: 10vh;
    }
    .items_container {
      .item span {
        font-size: $font-mb-12;
      }
    }
  }
  .work-thumb-wrapper{
    margin-bottom: calc(20px + 3.5vw);
    .text-wrapper{
      .work-type {
        margin-bottom: 8px;
        font-size: 10px;
      }
      .title{
        margin-bottom: 6px;
      }
    }
    .picture img{
      height: 260px;
    }
  }
  .work-buttons{
    overflow: auto;
    // -webkit-box-reflect: right;
    padding: 0 $p-mb-container calc(20px + 1.5vw);
    width: 100%;
    justify-content: initial;
    // margin: 0 $p-mb-container;
    flex-wrap: initial;
    .nav-link{
      &.active{
        border-color: $blue-5 !important;
      }
    }
    .nav-item{
      margin-right: 15px;
    }
    //Estilos para el scroll ------------------------------------------
    &::-webkit-scrollbar {
      background-color: $blue-2;
      height:10px;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb{
      border-radius: 8px;
      background: $blue-5;
    }
    &::-webkit-scrollbar-track-piece:end{
      margin-right: $p-mb-container;
      background: $blue-3;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-track-piece:start{
      margin-left: $p-mb-container;
      background: $blue-3;
      border-radius: 8px;
    }
    //Fin de estilos para el scroll -----------------------------------
  }
}