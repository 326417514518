.lest-talk {
  .views-container{
    .page-content{
      padding-bottom: 0;
      background: $blue-3;
    }
    .page-footer,
    .page-footer-wrapper {
      background: $blue-1;
    }
  }
  .page-footer .page-footer-links .social li a svg{
    fill: $blue-3 !important;
  }
  .page-header,
  .views-container {
    background-color: $blue-3;
  }
  .image-wrapper {
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .left-col {
    align-self: flex-end;
    position: relative;
    h1 {
      font-size: $font-85;
      color: #fff;
      font-weight: 200;
      padding-left: 2vw;
      padding-bottom: 10px;
      span{
        display: inline-block;
        position: relative;
        font-size: $font-74;
        opacity: 0;
        // animation-delay: 0.15s !important;
        // animation: line_enter 2s ease-out;
        // animation-fill-mode: forwards;
      }
    }
    .content-arrow-blue{
      bottom: 30px;
    }
  }
  .right-col {
    position: relative;
    margin-top: calc(14px + 1.6vw);
    h2 {
      font-size: $font-48;
      font-weight: 400;
      color: #fff;
      margin-bottom: 22%;
      span {
        position: relative;
        // animation-delay: 0.15s !important;
        // animation: line_enter 2s ease-out;
        // animation-fill-mode: forwards;
        display: block;
        font-weight: 400;
        overflow: hidden;
        opacity: 0;
      }
    }
  }
}
.info-work{
  padding: 0 calc($p-container + 30px);
  margin-top: 6%;
  justify-content: space-between;
  padding-bottom: 15%;
  &>div{
    display: flex;
    padding: 0;
    // &:first-child{
    //   padding-right: 5%;
    // }
    p{
      color: white;
      font-size: calc(7px + .8vw);
      margin-bottom: 0;
      line-height: 28px;
    }
    svg{
      fill: white;
      width: 10vw;
      max-width: 66px;
      margin-right: 30px;
      height: 50%;
      max-height: 62px;
    }
    &:first-child{
      svg{
        width: 15vw;
      }
    }
  }
}
@media only screen and (max-width: $min-query){
  .lest-talk{
    .page-header{
      padding-bottom: calc(10px + 1vw);
    }
    .views-container .page-content{
      padding-bottom: 100px;
    }
    .page-header .page-header-wrapper{
      .text-wrapper{
        .left-col{
          order: 1;
          .content-arrow-blue{
            bottom: -10px;
          }
        }
        .right-col{
          order: 2;
          h3{
            .text-word {
              font-size: $font-mb-10;
              display: block;
              // padding-left: 2%;
            }
          }
          h2{
            padding-left: 0;
            font-size: $font-mb-25;
          }
        }
      }
    }
  }
  .info-work{
    padding: 0 $p-mb-container;
    padding-top: 15%;
    margin-top: 0;
    padding-bottom: 0%;
    &>div{
      display: initial;
      padding: 0;
      margin-bottom: 40px;
      &:first-child{
        margin-right: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }
      p{
        color: white;
        font-size: $font-mb-18;
        font-weight: 400;
        max-width: 550px;
        width: 100%;
      }
      svg{
        fill: white;
        margin-bottom: 30px;
        max-width: 60px;
        // width: 14vw;
      }
      &:first-child{
        margin-bottom: 60px;
        svg{
          width: 14vw;
          // margin-bottom: 50px;
        }
      }
      &:last-child{
        svg{
          width: 12vw;
        }
      }
    }
    .btn-outline-white{
      display: block;
      margin: auto;
      width: 90%;
      padding: 14px 40px;
      font-size: $font-mb-22;
    }
  }
}