.tools-container {
  background-color: $bg-1;
  position: relative;
  margin-top: -2px;
  padding-bottom: calc(82px + 6vw);
  h2 {
    font-size: $font-74;
    color: $blue-4;
    line-height: 1.2em;
    margin-bottom: calc(70px + 3vw);
    .text-line {
      display: block;
      opacity: 0;
      font-weight: 400;
    }
  }
  ul {
    display: flex;
    align-items: center;
    justify-content: space-around;
    li {
      flex: 0 0 20%;
      padding: 20px;
      opacity: 0;
      img {
        width: 100%;
        position: relative;
        margin: 0 auto;
        mix-blend-mode: multiply;
        display: block;
      }
    }
  }
  .tools-container-wrapper{
    h2{
      font-size: calc(18px + 1.8vw);
    }
  }
}
@media only screen and (max-width: $min-query){
  .tools-container{
    // top: -32vh;
    padding-bottom: calc(20px + 5vw);
    .tools-container-wrapper{
      padding-left: calc(18.71429px + 1vw) !important;
      padding-right: calc(18.71429px + 1vw) !important;
    }
    h2{
      margin-bottom: calc(40px + 3vw);
      .text-line{
        font-weight: 400;
      }
    }
    ul{
      display: flex;
      align-items: center;
      justify-content: left;
      flex-wrap: wrap;
      flex: 1;
      padding-left: 0;
      li{
        width: 50%;
        flex: 0 0 50%;
        padding: 10px;
        img{
          width: 100%;
          object-fit: contain;
          max-width: 200px;
        }
      }
    }
  }
}
