//font-size
$font-16: calc(15px + 0.08vw);
$font-20: calc(5px + 0.8vw);
$font-22: calc(6px + 0.8vw);
$font-24: calc(9px + 0.8vw);
$font-25: calc(10px + 0.8vw);
$font-26: calc(16px + 0.5vw);
$font-30: calc(20px + 0.5vw);
$font-32: calc(19px + 0.8vw);
$font-35: calc(20px + 0.8vw);
$font-41: calc(18px + 1.2vw);
$font-48: calc(18px + 2vw);
$font-62: calc(24px + 2vw);
$font-74: calc(20px + 2.6vw);
$font-80: calc(32px + 2.5vw);
$font-85: calc(37px + 2.5vw);
$font-108: calc(60px + 2.5vw);
// font-size query
$font-mb-36: calc(34px + 0.5vw);
$font-mb-25: calc(17px + 2.5vw);
$font-mb-24: calc(16px + 2.5vw);
$font-mb-22: calc(14px + 2.5vw);
$font-mb-20: calc(12px + 2.5vw);
$font-mb-18: calc(10px + 2.5vw);
$font-mb-16: calc(8px + 2.5vw);
$font-mb-14: calc(6px + 2.5vw);
$font-mb-14_1: calc(6px + 2vw);
$font-mb-19: calc(18px + .2vw);
$font-mb-10: calc(6px + 1.4vw);
$font-mb-12: calc(2px + 3vw);

//media query
$min-query: 768px;
$p-container: calc(25.71429px + 1.42857vw);
$p-mb-container: calc(15.71429px + 1vw);
$p-mb-container-negative: calc(-15.71429px - 1vw);

$timing : 20s;
