$blue-1: #003979;
$blue-2: #004f9f;
$blue-3: #0068b6;
$blue-4: #166fd4;
$blue-5: #0081c5;
$blue-6: #009dd7;

$blue-7: #156ec7; //Nuevo
$blue-8: #00deff; //Nuevo
$blue-9: #0072ff;
$blue-10: #001441;
$blue-11: #00d6ff;
$blue-12: #75baff;
$blue-13: #0092ff;

$skyblue-1: #00b7e7;
$skyblue-2: #54c9f0;
$skyblue-3: #87ddf9;
$skyblue-4: #b6eafb;
$skyblue-5: #e6f8ff;

$gray-1: #676262; 
$gray-2: #909090;

$bg-1: #f7f9fb;

$white-1: #ebf3f9; //Nuevo

$gray-1: #5a6275; //Nuevo

$black-1: #000000; //Nuevo