.fields-container {
  background-color: $bg-1;
  padding-top: calc(60px + 6vw);
  position: relative;
  h2 {
    font-weight: 400;
  }
  h3 {
    font-size: $font-25;
    color: $gray-1;
    line-height: 1.2em;
    max-width: 34%;
    margin-left: 50%;
    padding-top: calc(30px + 2vw);
  }
  .fields-items-wrapper {
    margin-top: calc(42px + 4vw);
  }
  .field-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    justify-content: space-around;
    padding: calc(57px + 6vw) calc(21px + 0.7vw) calc(26px + 1.5vw)
      calc(21px + 0.7vw);
      margin-left: calc(10px + 0.3vw);
      margin-right: calc(10px + 0.3vw);
    height: 100%;
    background-color: #ffffff;
    img {
      margin-bottom: calc(10px + 1.2vw);
      height: calc(60px + 2.2vw);
      max-width: calc(60px + 2.2vw);
      object-fit: contain;
      object-position: left;
    }
    h4 {
      font-size: $font-25;
      color: $blue-2;
    }
    p {
      font-size: $font-16;
      color: $gray-2;
      margin-bottom: 0;
    }
    .text-container{
      flex: 1;
    }
  }
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 15vh;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: $blue-1;
  }
}
@media only screen and (max-width: $min-query){
  .fields-container{
    h3{
      max-width: initial;
      font-size: $font-mb-14_1;
      margin-left: 32%;
    }
    .field-wrapper{
      h4{
        font-size: $font-mb-12;
      }
      p{
        font-size: $font-mb-10;
        line-height: 1.2em;
      }
    }
    .fields-items-wrapper{
      flex-wrap: wrap;
      margin-left: -15px !important;
      margin-right: -15px !important;
      .field-container{
        width: 50%;
        margin-bottom: calc(var(--bs-gutter-x) * 0.5);
        margin-top: calc(var(--bs-gutter-x) * 0.5);
        .field-wrapper{
          margin: 0;
          padding: 35% 10% 12% 10%;
          img{
            width: 50%;
            max-width: 200px;
          }
        }
      }
    }
  }
}